table.draggable-list-table {
    border-spacing: .5px;
    border-collapse: separate;
    table-layout: auto;
}

button.draggable-add-button {
    margin-left: 0;
}

.draggable-list-table td.draggable-list-header-cell {
    border-right: 0px;
    border-left: 0px;
}

.draggable-list-table td.draggable-list-header-cell-lead {
    border-right: 0px;
}

.draggable-list-table td.draggable-list-header-cell-tail {
    border-left: 0px;
}

.draggable-list-table-error {
     margin: 14px;
     color: #F44336;
}

.draggable-list-table-footer .knock-react-button {
    float: left
}

.draggable-list-table-footer {
    clear: both;
    display: inline-block;
    width: 100%;
}