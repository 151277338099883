.prospect-upload-container {

    .knock-react-modal-content {
        padding: 40px 60px;

        label {
            font-weight: bold;

            input {
                margin-left: 5px;
            }
        }
    }
}
