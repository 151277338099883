.property-info-edit-settings {
    padding: 20px;

      .settings-text-input-wrapper {
        margin-top: 10px;
        width: 320px;
      }

      .settings-switch {
        width: 380px;
      }
  }
