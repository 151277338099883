.add-integration-form {
  margin-top: 20px;

  .knock-react-flex {
    margin-bottom: 5px;

    div:first-child {
      margin-right: 10px;
      text-align: right;
      min-width: 140px;
    }

    input[type=text],
    input[type=password] {
      min-width: 200px;
    }

  }

   .add-onsite-integration-form {
    
    .knock-react-modal-content {
      min-width: 375px;
    }

    label {
      font-weight: bold;

        input {
          margin-left: 10px;
        }
    }
  }
}
