.username-status-icon {
    display: flex;
    justify-content: space-between;
}

.user-editor-container {
    
    label.required:after {
        content: "*";
    }
}
