span.actionable-icon {

  i {
    cursor: pointer;
    margin-right: 7px;
  }

  i:hover {
  	color: grey;
  }
}

div.hidden-elements {
  display: none;
}

.knock-react-table-row-dim {
  background-color: #EFEFEF;
}

.mLeft5 {
	margin-left: 5px;
}

.property-sources-properties {
  gap: 60px;
  margin-top: 12px;
}

.wire-frame-table td.phone-registration.table-data {
  text-align: center;
  text-transform: capitalize;
}

span.tracking-number-cell > * {
  margin: 0 3px;
  vertical-align: middle; 
   
  i {
    margin-right: 0;
  }
}
