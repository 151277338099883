.brand-content-page {
    max-width: 1096px;
    padding: 7px 15px;

    .brand-content-page-header {
        width: 900px;
    }

    .manage-brand-count-button {
        text-align: right;
    }

    .brand-content-data-table {
        padding-top: 12px;
        
        table {
            th, td {
                min-width: unset;
                max-width: unset;
            }
        }
        
        .multi-select-checkbox + .MuiFormControlLabel-label,
        .property-select-checkbox + .MuiFormControlLabel-label {
            font-size: unset;
            font-weight: unset;
        }

    }
}
