.select-container {
  display: flex;
  justify-content: space-between;
}

.campaign-associated-properties {
  margin-bottom: -24px;
  margin-top: 24px;
}

.campaign-trigger-display {
  margin-top: 24px;
}

.disabled-template-select,
.disable-days-to-delay {
  cursor: pointer;
  opacity: .4;
  pointer-events: none;
}
