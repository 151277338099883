.sheet-modal {

  .knock-react-modal {
    max-width: 575px;
  }
  
  .knock-react-flex {
    margin: 15px 0px;

    .field-label {
      font-weight: bold;
    }

    .field-input {
      position: relative;

      div:first-child {
        margin-right: 0px;
      }
    }

    div:first-child {
      margin-right: 10px;
      text-align: right;
      min-width: 145px;
    }
    
    input {
      min-width: 250px;
    }
  }
}

table {
  th, td {
    min-width: 120px;
    max-width: 600px;
  }

  .shaded {
    background: lightgrey;
  }

  .muted {
    color: grey;
  }
  
  .cell-value {
    margin-right: 3px !important;
  }

  .pull-right {
    float: right;
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.label {
  display: inline;
  padding: 1px 6px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.label-default {
  color: #fff;
  background-color: #777;
}

.label-success {
  color: #fff;
  background-color: #5cb85c;
}

.label-info {
  color: #fff;
  background-color: #5bc0de;
}

.label-primary {
  color: #fff;
  background-color: #337ab7;
}

.label-warning {
  color: #fff;
  background-color: #f0ad4e;
}

.label-danger {
  color: #fff;
  background-color: #d9534f;
}

.actionable {
  cursor: pointer;
}

.actionable:hover {
  opacity: 0.75;
}

button.btn-sm {
  margin: 0px;
  padding: 3px 6px;
  border-radius: 3px;
}

button.btn-md {
  margin: 14px 7px;
  padding: 8px 12px;
  border-radius: 3px;
}

button.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

button.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

button.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

button.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; 
}

.mRight5 {
  margin-right: 5px;
}

.mRight10 {
  margin-right: 10px;
}