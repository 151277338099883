.modal-view-header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modal-view-header-hide-button {
  cursor: pointer;
  border: none;
  border-radius: 30px;
  outline: none;
  padding: 5px;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: .5;
  color: #000;


  span {
    i {
      font-size: 18px;
    }
  }
}

.modal-view-header-hide-button:hover {
  opacity: .75;
  color: #000;
}
