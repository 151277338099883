.admin-users-page-container {

}

.admin-user-editor-container {
  margin-top: 20px;
  max-width: 1500px;

  .knock-react-modal {
    min-width: 600px;
    overflow-y: auto;
  }

  .knock-react-flex {
    align-items: center;
    margin-bottom: 5px;

    .modal-field-label {
      font-weight: bold;
      margin-right: 10px;
      text-align: right;
      width: 145px;
    }

    .modal-field-input {
      position: relative;
    }

    .modal-field-input div {
      margin-right: 10px;
      min-width: 290px;
    }

    .modal-field-input div select {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .input-append-text {
      position: absolute;
      top: 2px;
    }

    .modal-field-input div .icon {
      padding-left: 30px;
      width: 270px;
    }
    
    .modal-field-input div .icon .available {
      border: (2px solid rgba(92,184,92,.5));
    }

    .modal-field-input div .icon .unavailable {
      border: (2px solid rgba(244,67,54,.5));
    }

    .input-append-text i {
      margin-top: 3px;
      margin-left: 0;
    }

    input {
      width: 300px;
      max-width: 400px;
      margin-right: 5px;
    }

    input[type="checkbox"] {
      width: unset;
    }

    select {
      width: 200px;
    }

    .txt-success {
      color: #5cb85c !important;
    }

    .txt-danger {
      color: #F44336 !important;
    }
  }

  .leasing-team-search {
    font-family: FontAwesome;
    display: inline-flex;
    align-items: center;
    justify-content: center
  }

  .knock-react-input .leasing-team-search {
    background-color: white;
    border: none;
    font-family: inherit;
  }

  .leasing-teams-editor .knock-react-flex .modal-field-label{
    margin-top: 5px;
  }
}
