.call-intel-page {
  padding: 15px;

  .call-intel-page-description {
    max-width: 1000px;
    margin: 8px 0 64px 0;

    p {
      line-height: 1.5;
      color: #353950;
      letter-spacing: .15px;
    }
  }

  .edit-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .edit-icon-wrapper {
    svg {
      font-size: 14px;

      path {
        stroke:rgb(92, 92, 92);
      }
    }
  }

  .disable-switches {
    .call-intel-feature-switch {
      pointer-events: none;
    }
  }
}
