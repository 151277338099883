.greeting-recording-editor {
  
  .knock-react-modal {
    width: 400px;
  }

  div.title-column {
    min-width: 90px;
  }
  
  div.value-column {
    width: 100%;
  }

  .margin-none {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .corner-left {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .corner-right {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  input.input-longer {
    width: 80%;
  }

  button.btn-sm {
    padding: 3.5px 14px;
  }

  button.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
  }

  button.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
  }

  button.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
  }

  button.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a; 
  }

  div.hidden-elements {
    display: none;
  }

  .text-small {
    font-size: smaller;
  }

  .mTop5 {
    margin-top: 5px;
  }

  .mLeft10 {
    margin-left: 10px;
  }
}
