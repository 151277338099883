.drip-campaign-page {
    padding: 15px 40px;

    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .drip-campaign-page-description {
        max-width: 800px;
        margin: 24px 0;
    }

    .view-description {
        max-width: 760px;
        margin-bottom: 30px;
    }

    .campaign-view,
    .template-view {
        max-width: 1096px;
    }

    .menu-button { 
        text-align: right;
    }
}
