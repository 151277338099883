.a2p-registration-container {

  .half-width {
    width: 50%;
  }

  .half-width.readable-width {
    min-width: 500px;
  }

  .full-width {
    width: 100%;
  }

  .width-80 {
    width: 80%;
  }

  .inline-block {
    display: inline-block;
  }

  .pull-right {
  	float: right;
  }

  .brand-registration-data-link {
    box-sizing: border-box;
    padding: 24px;
    width: 800px;

    .registration-button-wrapper {
      text-align: right;
    }

    .brand-registration-lookup {
      margin-top: 25px;

      .lookup-sid-inputs {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .knock-react-flex {
    margin-bottom: 5px;

    .registration-field-label {
      font-weight: bold;
    }

    .registration-field-input {
      position: relative;

      div:first-child {
        margin-right: 0px;
      }

      input {
        min-width: 300px;

      }

      input.error {
        border-color: red;
      }
     
      select {
        min-width: 250px;
        border: 1px solid #E6E6E6;
        border-radius: 6px;
        outline: none;
        min-height: 20px;
        background-color: #EFEFEF;
        padding: 2px;
      }

      input.custom-checkbox {
        margin-left: 23px;
      }
    }

    & > div:first-child {
      margin-right: 10px;
      text-align: right;
      min-width: 180px;
    }
  }
}

.brand-registration-instructions {
  max-width: 700px;

  .question {
    margin-top: 10px;
    font-weight: bold;
  }
  .answer {
    margin-top: 5px;
  }
  
  .hide {
    display: none;
  }
}

.mTop5 {
  margin-top: 5px;
}

.mTop10 {
  margin-top: 10px;
}

span.bold {
  font-weight: bold;
}

span.larger {
  font-size: 18px; 
}
