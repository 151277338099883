.knock-data-cloud-setup {

  .setup-image-container {
    margin-top: 80px;
    text-align: center;
  }
      
  .setup-image-container > span {
    display: inline-block;
    position: relative;
  }
  
}
