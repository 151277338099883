.phone-relay-editor {
  .knock-react-flex {
    align-items: center;
    margin-bottom: 10px;

    &.error {
      align-items: baseline;
    }

    select {
      width: 100%;
    }

    input[type=radio]:nth-child(2) {
      margin-left: 10px;
    }

    div:first-child {
      min-width: 130px;
    }

    div:nth-child(2) {
      flex-grow: 1;
    }
  }
      
  .knock-react-form-hint-error {
    font-size: 12px;
  }
}
