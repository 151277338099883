
.call-intel-drawer {
  width: 100%;
  height: 100%;
  background: white;

  .toggle-wrapper {
    margin-top: 24px;
  }

  .toggles-list {
    margin-top: 32px;

    .toggles-list-description {
      margin-bottom: 16px;
    }
  }
}


.call-intel-dialog-text {
  display: block;
  margin-bottom: 20px;
}