.doorway-help-text {
  font-size: 12px;
}

.doorway-label-text {
  font-weight: bold;
}

.doorway-property-content-container {
  border: 1px solid gray;
  border-radius: 1rem;
  padding: 0 1rem;

  hr {
    border-top: 1px dashed gray;
  }

  .doorway-property-setting-item {
    display: flex;

    >:first-child {
      flex-basis: 18rem;
    }

    margin: 1rem 0;
  }
}

.target-number,
.dynamic-number-insertion-type {
  display: flex;
  margin: 1rem 0;

  :first-child {
    flex-basis: 18rem;
    min-width: 18rem;
  }

  .tooltip {
    margin-left: 10px;
  }
}

.target-number {

  label span {
    font-size: 12px;
    margin-left: 12px;
  }

  input:focus {
    outline: 1px solid -webkit-focus-ring-color;
  }
}

.dynamic-number-insertion-type select {
  text-transform: capitalize;
}
