
$class_prefix: admin-web__syndication__components__dPHxbW__Grid;

// Grid
$grid_gutter: 20px;
$grid_width: 95%;

// Misc
$checkbox_width: 12px;

// Columns
$col_padding: 10px;
$col_width: 75px;
$col_width_large: 125px;
$col_rotated_translate: 0.5*($col_width + (2*$col_padding)) - 0.5*$checkbox_width;

// Row viewing window
$row_window_height: 60vh;

// Rows
$row_header_height_rotated: 95px;
$row_header_background_color: white;


.#{$class_prefix}-grid {
  display: flex;
  flex-direction: column;
  width: $grid_width;
  padding: $grid_gutter;
  overflow-x: auto;

  .#{$class_prefix}-row-window {
    height: $row_window_height;
    position: relative;
    overflow-y: auto;
  }

  .#{$class_prefix}-row {
    display: inline-flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-top:  $grid_gutter;
    min-width: 60%;

    &.#{$class_prefix}-header {
      align-items: center;
      margin-top: 0px;
      position: sticky;
      background-color: $row_header_background_color;
      top: 0;
    }

    &.#{$class_prefix}-header-rotated {
      align-items: flex-end;
      height: $row_header_height_rotated;
      top: 0;
    }

    &.#{$class_prefix}-header-border {
      box-shadow: 0 10px 10px -16px grey;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .#{$class_prefix}-col {
      width: $col_width;
      padding: $col_padding;
      margin-left: $grid_gutter;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;

      &:first-child {
        width: $col_width_large;
        justify-content: flex-start;
        margin-left: 0;
        font-weight: bold;

        > * {
          max-width: $col_width_large;
        }
      }

      &.#{$class_prefix}-heading {
        font-weight: bold;
        text-align: center;
      }

      &.#{$class_prefix}-heading-rotated {
        display: inline-block;
        width: $col_width_large;
        margin-right: $col_width - $col_width_large;
        justify-content: flex-start;
        transform: translate($col_rotated_translate) rotate(-30deg);
        transform-origin: bottom left;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }

      > * {
        max-width: $col_width;
      }
    }
  }
}


/////////// MEDIA QUERIES ///////////////////////////
$mobile_screen_size: 480px;
$tablet_screen_size: 768px;
$desktop_screen_size: 992px;
$large_desktop_screen_size: 1200px;

/* Smaller than 480px (mobile) */
@media only screen and (min-width: $mobile_screen_size) {}

 /* Smaller than 768px (tablet) */
@media only screen and (min-width: $tablet_screen_size) {}

@media only screen and (min-width: $desktop_screen_size) {}

@media only screen and (min-width: $large_desktop_screen_size) {}