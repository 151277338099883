.admin-users-page-container {

  h1 {
    margin-bottom: 5px;
  }

  button.add-new-user {
    margin: 0px 0px 10px 0px;
  }

  table {
    border-collapse: collapse;
    border: 1px solid black;

    tr {
      min-height: 50px;
    }

    td, th {
      border-left: 1px solid black;
      border-bottom: 1px solid black;
      padding: 4px;
      text-align: left;
      white-space: normal;

    }

    td button {
      margin-left: 0px;
    }

    td div.leasing-teams {
      white-space: normal;
      word-break: break-word;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 150px;
      min-height: 50px;
      font-size: smaller;
      display: block;
      border: none;
      width:  100%
    }

    td div {
      border: none;
    }
  }
}