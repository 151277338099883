.ai-template-container {
  .rte-label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .message-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .assigned-properties {
    margin-top: 46px;
  }
}