.draggable-list-table td.draggable-list-cell{
  box-sizing: border-box;
  padding: 0px;
  background-color: white;
  border-left: 0px;
  border-right: 0px;
  border-color: grey;
}

.draggable-list-cell-value{
  margin: 0px;
  font-size: inherit;
  padding: 0px .5em 0em .5em;
  border: 0px;
}

.draggable-list-cell-value-input {
  margin: 0px .5em 0em .5em;
  padding: 0px;
  border: 0px;
  width: 85%
}