.company-template-list {

    .company-template-accordion-summary {
        display: flex;
        flex: 1;
        justify-content: space-between;
    }

    .accordion-div {
        flex: 1;
    }

    .template-row {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
    }

     .template-row-data {
        display: flex;
        align-items: center;

        .enabled-property-ratio {
            width: 100px;
        }
    }

}
