.add-integration-buttons {
  max-width: 1000px;

  .add-integration-button {
    width: 210px;
  }
}

span.txt-disclaimer {
  color: grey;
  font-size: x-small;
  font-style: italic;
  margin-right: 20px;
}