$light-grey: rgba(60, 64, 67, 0.3);
$focused-box-shadow: $light-grey -.5px 0px 3px 1px, $light-grey .5px 0px 3px 1px;

.draggable-list-table tr.draggable-list-row:hover {
  box-shadow: $focused-box-shadow;
  border: 5px black;
}

.draggable-list-table tr.draggable-list-row-editing {
  box-shadow: $focused-box-shadow;
  border: 5px black;
}

.draggable-list-table tr.draggable-list-row-locked {
  box-shadow: 0px;
  border: 5px black;
}


.draggable-list-table td.draggable-list-icon-cell {
  min-width: 0px;
  border-right: 0px;
  background-color: white;
  border-color: grey;
}

.draggable-list-table td.draggable-list-buttons-cell {
  border-color: grey;
  border-left: 0px;
  padding: 0px;
  background-color: white;
  height: 100%;
}