.scheduling-preferences-edit {
    margin-top: 10px;
    padding: 16px;

    .scheduling-preferences-edit-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
         max-width: 800px;
    }

    .scheduling-preferences-edit-list {
        max-width: 800px;


        .MuiListItem-button:active {
            background-color: #E6E6E6;
        }

        .MuiListItem-container:hover,
        .MuiListItem-button:hover {
            background-color: #F2F2F2;
        }

        .accordion-div {
            flex: 1;
        }

        .batch-disabled {
            background-color: #f1f1f1;
            opacity: 0.4;
            pointer-events: none;
        }
    }
}
