.property-templates-container {
  > div {
    margin-bottom: 10px;
  }

  .property-templates-chars-left {
    font-size: 12px;
    position: relative;
    top: -5px;
    left: 4px;
  }
}

.property-templates-container textarea {
    min-width: 768px;
    min-height: 100px;
    padding: 15px;
    box-sizing: border-box;
}

.property-templates-container input[type="checkbox"]:not([class="auto-response-switch-for-all"]) {
  margin: 15px 0 15px 0;
}
.auto-response-switch-for-all {
  margin-left: 0;
}

.property-templates-container input:not([type="checkbox"]) {
  min-width: 768px;
  margin-block: 10px;
}

.property-templates-container .email-subject {
  min-width: 660px;
  margin-right: 10px;
}

.property-template-checkbox {
  label {
    margin-left: 10px;
  }
}

.horizonatal-rule {
  margin-top: 15px;
  border-top: 1px solid black;
}

.disable-suppressed {
  opacity: .5;
  pointer-events: none;
}

.link-padding {
  padding-left: 5px;
}
