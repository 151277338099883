.selfie-scan-settings-container {
  .selfie-scan-settings-table-container {
    display: flex;
    flex-direction: row;

    .tooltip {
      height: fit-content;
      margin: 0 1rem;
    }
  }

  .selfie-scan-settings-mobile-tours-enabled-text {
    margin-bottom: 1rem;
  }

  .selfie-scan-settings-table {
    display: block;
    width: fit-content;

    tbody {
      display: block;
      overflow-y: auto;
      max-height: 12rem;
    }
  }
}
