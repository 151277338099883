.company-sources-tab {
    
    .add-company-source {
        margin-bottom: 38px;
    }

    .company-sources-list {
        width: 685px;
    }
}
