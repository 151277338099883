.sources-page {
    &.tabbed-sources {
        .page-heading {
            margin-bottom: 0;
        }
        .spreadsheet-link {
            color: #000;
        }
    }
}
