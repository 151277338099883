.tours-self-scan-results-container {
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  padding: 0 1rem 2rem 1rem;

  h4 {
    margin-bottom: 0.5rem;
  }

  .tours-self-scan-results-message-container {
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    padding: 2rem 1rem;
    width: 75%;
  }

  &:first-child {
    margin: 0 0 1rem 0;
  }
}

.tours-self-scan-disabled-container {
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  padding: 2rem 1rem;
}
