@import '~@knockrentals/knock-react/_knockReactColors';

.user-editor-container {
  margin-top: 20px;

  .knock-react-modal {
    width: 700px;
  }

  .knock-react-modal-header h2 {
    margin-bottom: 0;
  }

  .knock-react-modal-content{
    padding: 40px;
  }

  .divider {
    display: block;
    width: 100%;
    min-width: 100%;
    height: 1px;
    margin: 12px 0;
    box-sizing: border-box;
    padding: 0;
    line-height: 1.5;
    background: $knock-react-border-color;
  }

  .knock-react-flex {
    margin-bottom: 15px;
    justify-content: center;

    & > div + div {
      margin-left: 10px;
    }

    select.knock-react-select {
      border: 1px solid $knock-react-border-color;
      border-radius: 6px;
      outline: none;
      height: 26px;
      background-color: $knock-react-input-background-color;
    }

    .modal-field-label{
      margin-right: 10px;
    }

    .modal-field-input {
      position: relative;
    }

    .modal-field-input div select {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .input-append-text {
      position: absolute;
      top: 2px;
      left: 120px;
    }

    .input-append-text.txt-success {
      left: 120px !important;
    }

    .input-append-text.txt-danger {
      left: 90px !important;
    }

    input {
      width: 200px;
    }

    select {
      width: 200px;
    }

    .txt-success {
      color: #5cb85c !important;
    }

    .txt-danger {
      color: #F44336 !important;
    }
  }

  .knock-react-flex:last-of-type {
    margin-bottom: 0;
  }

  .leasing-teams-editor {
    margin: 15px 15px 15px 0;
    flex: 0 1 auto;

    input.knock-react-input {
      width: auto;
    }
  }

  .leasing-team-mappings {
    flex: 0 1 auto;
    min-width: 200px;

  }

  .leasing-team-mapping label {
    font-size: 12px;
  }

  .leasing-team-mapping + .leasing-team-mapping {
    margin-top: 15px;
  }
}

.delete-button-tooltip {
  margin-right: 5px
}


