.scheduling-preferences {
    padding: 16px 24px;

    .scheduling-preferences-description {
        margin-top: 12px;
    }

    .property-chips {
        margin-top: 20px;
        margin-bottom: 6px;
    }
}
