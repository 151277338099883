.access-settings-page-container {
  .access-settings-table {
    border-collapse: collapse;
  }

  .access-settings-header {
    text-align: center;
    font-size: 16;
    font-weight: bold;
  }

  .access-settings-role {

  }

  .access-settings-select {
    text-align: center;
    vertical-align: middle;
  }
}
