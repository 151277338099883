.tours-hours-day-settings {
    .hours {
        display: inline-block;
        margin-right: 110px;

        .separator-text {
            margin: 0 15px;
        }
    }

    .max-visitors {
        display: inline-block;
    }
}
