.login-page-container {

    .login-button {  
        margin: 2rem 0 0; 
        width: 100%;
    }

    .password-wrapper {
        margin-top: 2rem;
    }

    .password-reset-wrapper {
        margin: 8px 8px 0;
        text-align: right;
    }
}
