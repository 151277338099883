.logo-upload-container  {
    align-items: center;
    display: flex;
    gap: 24px;

    .upload-container {
        flex-grow: 1;
        max-width: 650px;
    }

    .logo-wrapper {
        padding: 4px;
    }
}
