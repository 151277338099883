.leasing-teams-editor {
  .txt-success {
    color: #5cb85c !important;
  }

  .txt-danger {
    color: #F44336 !important;
  }

  .button-add {
    color: rgba(92, 184, 92, 1) 
  }

  .button-remove {
    color: rgba(244, 67, 54, 1) 
  }

  .inline-list-button {
    border-radius: 100%;
    background-color: rgba(10, 10, 10, 0);
    border: none;
    height: 15px;
  }

  button {

    i {
      margin-left: 8px;
    }
  }

  .modal-list {
    list-style-type: none;
    margin: 0;
    padding-bottom: 5px;
    overflow: auto;
    max-width: 400px;
    max-height: 150px !important;
    display: block;
    padding-inline-start: inherit;
  }

  .modal-list-item {
    display: inline-block;
    margin: 1px 1px 1px 1px;
    border-radius: 50px;
    border: 1px solid rgba(10, 10, 10, .1);
    height: 20px;
    padding: 2px 4px;
  }

  .add-all {
    background-color: rgba(92, 184, 92, 1);
    margin: 0px 5px 0px 0px;
  }

  .remove-all {
    background-color: rgba(244, 67, 54, 1);
    margin: 0px 5px 0px 0px;
  }

  .leasing-team-search {
    font-family: FontAwesome;
    display: inline-flex;
    align-items: center;
    justify-content: center
  }

  .knock-react-input .leasing-team-search {
    background-color: white;
    border: none;
    font-family: inherit;
  }

  .leasing-teams-editor .knock-react-flex .modal-field-label {
    margin-top: 5px;
  }

  .read-only-leasing-teams {
    pointer-events: none;
    opacity: 0.3;
  }
}
