.company-template-container {

  .message-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .property-error {
    border: 2px solid #F599A8;
  }
  
  .assigned-properties,
  .unassigned-properties {
    margin-top: 46px;
  }

  .editor-disabled {
    cursor: pointer;
    opacity: .6;
    pointer-events: none;
  }
}
