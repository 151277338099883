.quick-reply-container {

    .email-container {
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .assigned-properties,
    .sms-container {
        margin-top: 46px;
    }
}
