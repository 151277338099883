.scheduling-grid {

    .scheduling-tooltip {
        vertical-align: middle;
    }

    .edit-scheduling-button {
        text-align: right;
    }

    .scheduling-grid-data-table {
        margin-top: 15px;

        .data-table-paper, 
        .data-table-paper > div:first-child {
            border-radius: 8px;
        }

        table {
            th, td {
                min-width: unset;
                max-width: unset;
            }
        }

        .multi-select-checkbox + .MuiFormControlLabel-label,
        .property-select-checkbox + .MuiFormControlLabel-label {
            font-size: unset;
            font-weight: unset;
        }
    }
}
