.custom-filters-modal {

  .knock-react-modal {
    overflow-y: visible;
    width: 200px;
    text-align: center;
  }

  .knock-react-modal-header {
    text-align: center;
  }

  .validation-error {
    font-size: 12px;
    color: red;
    height: 15px;
    padding-bottom: 10px;
  }

  .validation-error-hidden {
    visibility: hidden;
    height: 15px;
    padding-bottom: 10px;
  }

  .knock-react-input-error {
    border-color: red;
  }
  
  .modal-field-label {
    text-align: left;
    padding-left: 56px;
  }
}


.wire-frame-table tr td.tooltip-cell {
  border: none;
  padding-left: 10px;
}

.group-settings {
  .wire-frame-table tr td:first-child {
    padding-left: 8px;
    padding-right: 8px;
  }
}
