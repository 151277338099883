.access-settings-page {
    padding: 15px 40px;

    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .access-settings-page-description {
        max-width: 800px;
        margin: 24px 0 40px;
    }
}
