.knock-admin-app.admin-omnibar {
  position: static;
  display: block;

  .knock-admin-app-display-col.knock-admin-app-content-col {
    bottom: 0;
    left: 0;
    min-width: 900px;
    padding: 40px 40px 81px;
    position: fixed;
    right: 0;
    top: 56px;
  }

  .knock-admin-app-display-col.knock-admin-app-navbar-col + .knock-admin-app-display-col.knock-admin-app-content-col {
    left: 155px;
  }

  .knock-admin-app-navbar-col {
    bottom: 0;
    overflow: auto;
    position: fixed;
    top: 56px;
  }

  --header-color-background: #ffffff;
  --header-color-text: #292B3D;
  --header-item-color-background-hover: #F7F7F8;
  --header-shadow: #E2E3E9 0 1px;
  --menu-item-background-color-active:#CBCDD8;
  --menu-item-background-color-hover: #F7F7F8;
  --scope-subtitle-color-text: #292B3D;
  --scope-selector-color-background-hover: #F7F7F8;
  --user-menu-avatar-color-background: #EEEEF2;
  --user-menu-avatar-color-text: #292B3D;
  --user-menu-avatar-color-text-active: #CBCDD8;
  --user-menu-color-background-active: #CBCDD8;
  --user-menu-color-background-hover: #F7F7F8;

  .o-omnibar {
   hr.MuiDivider-root {
      height: unset;
      margin: unset;
   }
}

  .o-user__avatar {
    --user-menu-avatar-color-text-active: #292B3D;
  }
}
