.tooltip-wrapper {
    left: 275px;
    max-width: 890px;
    padding-right: 65px;
    position: absolute;
    right: 0;
    top: 30px;

    &.shift-layout {
        text-align: right;
    }
}
