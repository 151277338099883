.attribute-modal {

  .knock-react-modal {
    max-width: 575px;
  }
  
  .knock-react-flex {
    margin: 15px 0;

    .field-label {
      font-weight: bold;
    }

    .field-input {
      position: relative;

      div:first-child {
        margin-right: 0;
      }
    }

    .field-wrap {
      max-width: 370px;
      word-wrap: break-word;
    }

    div:first-child {
      margin-right: 10px;
      text-align: right;
      min-width: 145px;
    }
    
    input {
      min-width: 250px;
    }
  }
}

.attributes-nav-tabs-menu {
  width: 100%;
  display: inline-block;
  margin-bottom: 5px;
}

.attributes-nav-tabs-menu,
.attributes-nav-tab {
  min-width: 140px;
  float: left;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid;
}

.attributes-nav-tabs-menu,
.attributes-nav-tab.active {
  border: 1px solid; 
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
}

table {
  th, td {
    min-width: 120px;
    max-width: 600px;
  }

  .shaded {
    background: lightgrey;
  }

  .muted {
    color: grey;
  }
  
  .cell-value {
    margin-right: 3px !important;
  }

  .cell-value-wrap {
    text-align: left;
    white-space: normal;
    word-wrap: break-word;
  }

  .pull-right {
    float: right;
  }
}

.actionable {
  cursor: pointer;
}

.actionable:hover {
  opacity: 0.75;
}

button.btn-sm {
  margin: 0;
  padding: 3px 6px;
  border-radius: 3px;
}

button.btn-md {
  margin: 14px 7px;
  padding: 8px 12px;
  border-radius: 3px;
}

button.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

button.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

button.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

button.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; 
}

.mRight10 {
  margin-right: 10px;
}