.quick-reply-data-table {
    margin-top: 50px;

  table {
    th,
    td {
      min-width: unset;
      max-width: unset;
    }
  }

  .quick-replies-grid-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
