.ai-template-list {
  .template-row {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    height: 38px;
  }

  .template-row-data {
    display: flex;
    align-items: center;
    height: 38px;
    margin-right: 10px;

    .enabled-property-ratio {
      width: 100px;
    }
  }
}
