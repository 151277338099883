.property-info-page-container {

  .add-property-modal {
    z-index: 1;

    hr {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .knock-react-modal {
    min-width: 500px;
  }

  .property-info-nav-tabs-menu {
    width: 100%;
    display: inline-block;
  }

  .property-info-nav-tabs-menu .property-info-nav-tab {
    min-width: 140px;
    float: left;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid;
  }

  .property-info-nav-tabs-menu .property-info-nav-tab.active {
    border: 1px solid;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
  }

  .half-width {
    width: 50%;
  }

  .half-width.readable-width {
    min-width: 500px;
  }

  .full-width {
    width: 100%;
  }

  .inline-block {
    display: inline-block;
  }

  .side-padding-sm {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .side-padding-md {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .side-padding-lg {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .side-margin-sm {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .side-margin-md {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .side-margin-lg {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .vertical-margin-sm {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .vertical-margin-md {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .vertical-margin-lg {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  button.btn-clear-margin {
    margin: 0px;
  }

  button.btn-sm {
    margin: 0px;
    padding: 3px 6px;
    border-radius: 3px;
  }

  button.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
  }

  button.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
  }

  button.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
  }

  button.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
  }

  button.btn-input-inline {
    margin: 0px;
    min-width: 0px;
    padding: 7px 11px;
    margin-left: 5px;
  }

  button.btn-checkbox-inline {
    margin: 0px;
    min-width: 0px;
    padding: 0.5px 2px;
    border-radius: 2px;
    margin-left: 2.5px;
  }

  .txt-left {
    text-align: left !important;
  }

  .txt-success {
    color: #5cb85c !important;
  }

  .txt-warning {
    color: #f0ad4e !important;
  }

  .txt-danger {
    color: #d9534f !important;
  }

  .cross-sell-disabled {
    padding: 5px;
    width: 50%;
    margin-top: 10%;
  }

  .tooltip {
    margin: 0 0.5rem;
  }

  .application-information-container {
    .property-info-edit-field-input {
      .override-default-url-input {
        min-width: unset;
      }
    }
    .tooltip {
      border-bottom: unset;
    }
  }
}
