.drip-campaigns-data-table {
    margin-top: 15px;

    .data-table-paper, 
    .data-table-paper > div:first-child {
        border-radius: 8px;
    }

    table {
        th, td {
            min-width: unset;
            max-width: unset;
        }
    }
}
