.utilities-title {
	margin-bottom: 0px;
}

.utility-actions {
	margin-left: 25px;

	input {
		padding-top: 5px;
		padding-bottom: 5px;
		min-width: 250px;
		max-width: 350px;
	}
}

.utility-description {
	margin-left: 25px;
	font-size: small;
	max-width: 500px;

	ul {
		margin-top: 0px;
	}
}

.forget-guestcard-confirmation-modal {
	.forget-guestcard-confirmation-container {
		margin: 20px;
		text-align: center;

		i.fa-exclamation-triangle {
			font-size: 80px;
			color: #f0ad4e;
		}

		.forget-guestcard-confirmation-blurb {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}