.property-info-edit-container {
  h2 {
    margin-top: 45px;
    padding-right: 25px;
    border-bottom: 1px solid;
    border-color: #eee;
    color: #333;
  }

  .logo-container {
    margin-left: 24px;
  }

  .property-info-edit-field-container {
    margin-bottom: 25px;

    //these settings are for the doorway custom questions forms
    .property-info-edit-field-label {
      margin-right: 5px;
    }

    .property-info-edit-field-input {
      margin-left: 7px;
    }

    .form-buttons {
      margin-left: 25px;
    }

    .txt-left {
      text-align: left !important;
    }

    .url-tooltip-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 5px;
    }

    .input-container {
      width: 100%;

      .input-flex-container {
        display: inline-flex;
        flex-direction: column;
        align-items: baseline;

        .url-tooltip-container {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          margin-bottom: 5px;
        }

        .textarea-header-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin: 5px 0px 5px 0px;
        }

        .input-label-text {
          font-weight: bold;
        }
      }

      .input-url-label-container {
        display: flex;

        .input-url-label-container-text {
          margin-right: 1rem;
        }
      }
    }
  }

  .property-info-edit-formfield-container {
    height: 32px;
    display: flex;
    align-items: center;

    .property-info-edit-formfield-container-label {
      width: 240px;
      text-align: left;
    }

    .knock-react-input {
      width: 200px;
    }
  }

  .knock-react-flex {
    margin-bottom: 5px;

    .property-info-edit-field-label {
      font-weight: bold;
    }

    .property-info-edit-field-input {
      position: relative;

      div:first-child {
        margin-right: 0px;
      }

      select {
        min-width: 250px;
        border: 1px solid #E6E6E6;
        border-radius: 6px;
        outline: none;
        min-height: 20px;
        background-color: #EFEFEF;
        padding: 2px;
      }

      input.custom-checkbox {
        margin-left: 23px;
      }
    }

    .property-info-edit-field-input span.input-append-text {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 3.5px;
      margin-right: 7px;
      color: gray;
    }

    &>div:first-child {
      margin-right: 10px;
      text-align: right;
      min-width: 145px;
    }
  }

  .knock-react-flex:not(.input-auto) {
    input {
      min-width: 250px;
    }
  }
}

.property-info-edit-custom-item {
  display: flex;

  >:first-child {
    flex-basis: 18rem;
    min-width: 18rem;
  }

  margin: 1rem 0;

  .tooltip {
    margin: 0 1rem;
    height: fit-content;
  }
}

.property-info-edit-custom-faq-container {
  border: 1px solid lightgray;
  border-radius: 1rem;
  padding: 0.5rem 1rem;

  .property-info-edit-custom-question-edit-container {
    border: 1px solid lightgray;
    border-radius: 1rem;
    padding: 1rem 0.5rem 1rem 0;
    margin: 1rem;
  }

  .property-info-edit-custom-faq-item {
    display: flex;
    margin: 0.5rem 0;

    .property-info-edit-custom-faq-item-answer-text {
      font-style: italic;
    }
  }
}

.property-info-edit-custom-faq-item input[type="checkbox"]:disabled + label {
  color: #aaaaaa
}

.flex-end {
  display: flex;
  justify-content: end;
}

.property-info-include-in-quick-launch-section-container {
  padding: 0.25rem;

  .property-info-include-in-quick-launch-item-draggable-container {
    user-select: none;
    padding: 0.5rem;
    margin: 0 0 8px 0;
    border: 1px solid gray;
    border-radius: 0.25rem;
    background-color: white;

    .property-info-include-in-quick-launch-item {
      min-width: 16rem;
      display: flex;


      .enable-button-container {
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .drag-icon-container {
        margin-left: auto;
      }
    }
  }
}

.dragging-over-container {
  background-color: lightgray;
}

.new-line {
  padding: 8px;
}

.helper-text {
  font-style: italic;
  color: grey;
  margin-left: 6px;
  margin-top: 3px;
}

